import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './assets/locales/en.json';
import translationAR from './assets/locales/ar.json';
import { setLang, getlang } from "../src/globals/globals";

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    ar:
    {
        translation: translationAR
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem("lang") != null ? localStorage.getItem("lang").toLowerCase() : "en",
        // keySeparator: true, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

setLang(localStorage.getItem("lang") != null ? localStorage.getItem("lang").toLowerCase() : "en");
changeLang(localStorage.getItem("lang"), false);

// change lang method
export function changeLang(lang, isLoginPage = false) {
    var html = document.getElementsByTagName("html")[0];
    let selectedLang = localStorage.getItem("lang");
    if (lang) {
        if (lang == 'en') {
            html.classList.remove("arabic_version");
            html.setAttribute("dir", "ltr");
            i18n.changeLanguage('en');
            setLang('en');
            localStorage.setItem("lang", 'en');
        }
        else {
            html.classList.add("arabic_version");
            html.setAttribute("dir", "rtl");
            i18n.changeLanguage('ar');
            setLang('ar');
            localStorage.setItem("lang", 'ar');
        }
    }
    else  {
        if (selectedLang === "en") {
            i18n.changeLanguage('ar');
            setLang('ar');
            localStorage.setItem("lang", 'ar');
        }
        else {
            i18n.changeLanguage('en');
            setLang('en');
            localStorage.setItem("lang", 'en');
        }
    }
};

export default i18n;