import config from '../../config';
import { GO_LOGIN, SET_ACCESS_TOKEN, SET_REFRESH_ACCESS_TOKEN, SET_LOGIN, SET_PROFILE, SET_SAVED_CREDENTIALS } from '../constants/authConstants';

const initialState = {
    accessToken: null,
    refreshToken: null,
    isLogin: false,
    profile: null,
    savedCredentials: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload
            };
        case SET_REFRESH_ACCESS_TOKEN:
            return {
                ...state,
                refreshToken: action.payload
            };
        case SET_LOGIN:
            return {
                ...state,
                isLogin: action.payload
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload
            };
        case SET_SAVED_CREDENTIALS: {
            return {
                ...state,
                savedCredentials: action.payload
            }
        }
        default:
            return state;
    }
};

export default reducer;