/* eslint-disable no-unused-expressions */
import i18n from '../../i18n';
import * as Yup from "yup";
import { configureStore as store } from '../../store/configureStore';
import moment from 'moment';

const nameRegex = /^[A-Za-z]+$/;
const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


const refactoreUsers = (users, ignoreKey = false) => {
    let RefactoredUsers = [];
    if (ignoreKey) {
        users.map((Item, index) => {
            RefactoredUsers.push({
                email: Item.email,
                name: Item.firstname + ' ' + Item.lastname,
                gender: i18n.t(`users.${Item.gender}`),
                mobile: Item.mobile,
                status: Item.enabled ? i18n.t('users.ACTIVE') : i18n.t('users.INACTIVE')
            })
        })
    }
    else {
        users.map((Item, index) => {
            RefactoredUsers.push({
                key: index,
                email: Item.email,
                name: Item.firstname + ' ' + Item.lastname,
                gender: i18n.t(`users.${Item.gender}`),
                mobile: Item.mobile,
                status: Item.enabled ? i18n.t('users.ACTIVE') : i18n.t('users.INACTIVE')
            })
        })
    }
    return RefactoredUsers;
}

const getUsersCols = () => {
    let columns = [{
        title: i18n.t('login.email'),
        dataIndex: 'email',
        key: 'email',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.email.length - b.email.length,
    },
    {
        title: i18n.t('users.name'),
        dataIndex: 'name',
        key: 'name',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.name.length - b.name.length,

    },
    {
        title: i18n.t('users.gender'),
        dataIndex: 'gender',
        key: 'gender',
        sorter: (a, b) => a.gender.length - b.gender.length,
    },
    {
        title: i18n.t('users.mobile'),
        dataIndex: 'mobile',
        key: 'mobile',
        sorter: (a, b) => a.mobile.length - b.mobile.length,
    },
    {
        title: i18n.t('users.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.length - b.status.length,
    },
    ]
    return columns;
}

const getaddUserValidationSchema = (EditMode, brandSelected = false, branchSelected = false) => {
    let result;

    if (EditMode) {
        result = {
            email: Yup.string().required(i18n.t("global.Required")).matches(re, i18n.t('global.emailValid')),
            firstName: Yup.string().required(i18n.t("global.Required")),
            lastName: Yup.string().required(i18n.t("global.Required")),
            mobile: Yup.string().required(i18n.t("global.Required")).matches(/^01[0-2]|015\d{8}$/, i18n.t('global.mobileValidation')),
            gender: Yup.mixed().required(i18n.t("global.Required")),
            status: Yup.mixed().required(i18n.t("global.Required")),
        }
    }
    else {
        result = {
            email: Yup.string().required(i18n.t("global.Required")).matches(re, i18n.t('global.emailValid')),
            password: Yup.string().required(i18n.t("global.Required")).min(8, i18n.t('global.passwordMin', { min: '8' })).max(20, i18n.t('global.passwordMax', { max: '20' })),
            firstName: Yup.string().required(i18n.t("global.Required")),
            lastName: Yup.string().required(i18n.t("global.Required")),
            mobile: Yup.string().required(i18n.t("global.Required")).matches(/^01[0-2]|015\d{8}$/, i18n.t('global.mobileValidation')),
            gender: Yup.mixed().required(i18n.t("global.Required")),
        }
    }

    if (brandSelected && brandSelected != undefined) {
        result.selectedBrand = Yup.mixed().required(i18n.t("global.Required"))
    }
    if (branchSelected && branchSelected != undefined) {
        result.selectedBrand = Yup.mixed().required(i18n.t("global.Required"))
        result.selectedBranch = Yup.mixed().required(i18n.t("global.Required"))
    }


    return Yup.object({ ...result })

}

const getGenders = () => {
    return [
        {
            _id: 0,
            name: i18n.t("users.MALE"),
            key: "MALE"
        },
        {
            _id: 1,
            name: i18n.t("users.FEMALE"),
            key: "FEMALE"
        },
    ];
}


const getStatus = () => {
    return [
        {
            _id: 0,
            name: i18n.t("users.ACTIVE")
        },
        {
            _id: 1,
            name: i18n.t("users.INACTIVE")
        },
    ];
}

const initPriv = (key, refactore = false) => {
    let selectedPrivillages = store.getState().storage.adminsState.admins?.content[key]?.privileges || [];
    let Privillages =
        // store.getState().storage.GlobalState.Privillages ||
        [
            "USERS_VIEW",
            "USERS_EDIT",
            "ADMINS_VIEW",
            "ADMINS_EDIT",
            "BRANDS_VIEW",
            "BRANDS_EDIT",
            "LOCATION_VIEW",
            "LOCATION_EDIT",
            "DOC_VIEW",
            "DOC_EDIT",
            "FEEDBACK_VIEW",
            "FEEDBACK_EDIT",
            "CATEGORY_VIEW",
            "CATEGORY_EDIT",
            "SEND_PUSH_NOTIFICATION",
            "GIFTS_VIEW",
            "DASHBOARD_VIEW",
            "PAYMENTS_VIEW",
            "APPLY_COMPANSATE"
        ];
    let selectedPriv = [];
    let result = [];
    if (refactore) {
        if (selectedPrivillages && selectedPrivillages.length > 0) {
            selectedPrivillages.map((Item) => {
                selectedPriv.push(Item.name)
            })
        }
    }
    else {
        selectedPriv = selectedPrivillages;
    }

    Privillages.map((Item) => {
        result.push({
            selected: selectedPrivillages.length > 0 ? selectedPriv.includes(Item) : false,
            value: i18n.t(`privillages.${Item}`),
        })
    })
    return result
}


const initRoles = (key, type, defaultSelected = null) => {
    let selectedRoles = [];
    if (type == "ADMIN") {
        selectedRoles = store.getState().storage.adminsState.admins?.content[key]?.roles || [];
    }
    else if (type == "CUSTOMER") {
        selectedRoles = store.getState().storage.usersState.users?.content[key]?.roles || [];
    }
    let Roles =
        // store.getState().storage.GlobalState.roles || 
        [
            i18n.t("roles.CUSTOMER"),
            i18n.t("roles.ADMIN"),
            i18n.t("roles.BRANCH"),
            i18n.t("roles.BRAND"),
        ];
    let selectedroles = [];
    let result = [];
    if (selectedRoles && selectedRoles.length > 0) {
        selectedRoles.map((Item) => {
            selectedroles.push(i18n.t(`roles.${Item.name}`))
        })
    }

    if (defaultSelected) {
        if (defaultSelected == i18n.t('roles.ADMIN')) {
            Roles.map((Item, index) => {
                result.push({
                    selected: Item == i18n.t('roles.ADMIN') ? true : false,
                    value: Item
                })
            })
        }
        else if (defaultSelected == i18n.t('roles.CUSTOMER')) {
            Roles.map((Item, index) => {
                result.push({
                    selected: Item == i18n.t('roles.CUSTOMER') ? true : false,
                    value: Item
                })
            })
        }
    }
    else {

        Roles.map((Item, index) => {
            result.push({
                selected: selectedRoles.length > 0 ? selectedroles.includes(Item) : false,
                value: Item
            })
        })
    }

    return result
}



const getResetPasswordValidationSchema = () => {
    return Yup.object({
        password: Yup.string().required(i18n.t("global.Required")).min(8, i18n.t('global.passwordMin', { min: '8' })).max(20, i18n.t('global.passwordMax', { max: '20' })),
        rePassword: Yup.string().required(i18n.t("global.Required")).oneOf([Yup.ref('password'), null], i18n.t("global.passwordMatchError")).min(8, i18n.t('global.passwordMin', { min: '8' })).max(20, i18n.t('global.passwordMax', { max: '20' }))

    })
}

const refactorePrivillages = (data) => {
    let selectedPriv = [];
    if (data.length > 0) {
        data.map((Item) => {
            selectedPriv.push(Item.name)
        })
    }
    return selectedPriv;
}

const getSelectedBrand = (id) => {
    const allBrands = store.getState().storage.brandsState.allBrands || [];
    let selectedBrand = null;

    allBrands?.map((Item) => {
        if (Item.id == id) {
            selectedBrand = Item;
        }
    })

    return selectedBrand
}
const getSelectedBranch = (id) => {
    const allBranches = store.getState().storage.brandsState.allBranches || [];
    let selectedBranch = null;

    allBranches?.map((Item) => {
        if (Item.id == id) {
            selectedBranch = Item;
        }
    })

    return selectedBranch
}

const getCompansateValidationSchema = () => {
    return Yup.object({
        amount: Yup.string().required(i18n.t("global.Required")),
        type: Yup.mixed().required(i18n.t("global.Required"))
    })
}


const getWalletsTypes = (form) => {
    return [
        {
            _id: form?.redeeming?.id,
            name: i18n.t(`users.${form?.redeeming?.type}`)
        },
        {
            _id: form?.sending?.id,
            name: i18n.t(`users.${form?.sending?.type}`)
        },
    ];
}


const refactoreTransaction = (data, type = "user", ignoreKey = false) => {
    let Refactored = [];
    if (type == "user") {
        if (ignoreKey) {
            data.map((Item, index) => {
                Refactored.push({
                    type: i18n.t(`brands.${Item.type}`),
                    amount: Item.amount,
                    brandName: Item.brandName,
                    branchName: Item.branchName,
                    createdDate: moment(Item.createdDate).format("DD-MM-YYYY, HH:mm:ss")
                })
            })
        }
        else {
            data.map((Item, index) => {
                Refactored.push({
                    key: index,
                    type: i18n.t(`brands.${Item.type}`),
                    amount: Item.amount,
                    brandName: Item.brandName,
                    branchName: Item.branchName,
                    createdDate: moment(Item.createdDate).format("DD-MM-YYYY, HH:mm:ss")
                })
            })
        }
    }
    else {
        if (ignoreKey) {
            data.map((Item, index) => {
                Refactored.push({
                    type: i18n.t(`brands.${Item.type}`),
                    amount: Item.amount,
                    userName: Item.userName,
                    branchName: Item.branchName,
                    createdDate: moment(Item.createdDate).format("DD-MM-YYYY, HH:mm:ss")
                })
            })
        }
        else {
            data.map((Item, index) => {
                Refactored.push({
                    key: index,
                    type: i18n.t(`brands.${Item.type}`),
                    amount: Item.amount,
                    userName: Item.userName,
                    branchName: Item.branchName,
                    createdDate: moment(Item.createdDate).format("DD-MM-YYYY, HH:mm:ss")
                })
            })
        }
    }
    return Refactored;
}

const getTransactionCols = (type = "user") => {
    let columns = [{
        title: i18n.t('feedback.type'),
        dataIndex: 'type',
        key: 'type',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.type.length - b.type.length,
    },
    {
        title: i18n.t('gifts.amount'),
        dataIndex: 'amount',
        key: 'amount',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.amount.length - b.amount.length,

    }]
    if (type == "user") {
        columns.push({
            title: i18n.t('brands.brandName'),
            dataIndex: 'brandName',
            key: 'brandName',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.brandName.length - b.brandName.length,
        },
            {
                title: i18n.t('brands.branchName'),
                dataIndex: 'branchName',
                key: 'branchName',
                sorter: (a, b) => a.branchName.length - b.branchName.length,
            })
    }
    else if (type == "branch") {
        columns.push({
            title: i18n.t('users.addUSer.userName'),
            dataIndex: 'userName',
            key: 'userName',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.userName.length - b.userName.length,
        })
    }
    else {
        columns.push({
            title: i18n.t('users.addUSer.userName'),
            dataIndex: 'userName',
            key: 'userName',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.userName.length - b.userName.length,
        },
            {
                title: i18n.t('brands.branchName'),
                dataIndex: 'branchName',
                key: 'branchName',
                sorter: (a, b) => a.branchName.length - b.branchName.length,
            })
    }


    columns.push(
        {
            title: i18n.t('global.date'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: (a, b) => a.createdDate.length - b.createdDate.length,
        },
    );

    return columns;
}


const refactorePayments = (users, ignoreKey = false) => {
    let Refactored = [];
    if (ignoreKey) { 
        users.map((Item, index) => {
            Refactored.push({
                amount: Item.amount,
                createdDate: moment(Item.createdDate).format('DD-MM-YYYY'),
                paymentMethod: i18n.t(`payments.${Item.paymentMethod}`),
                status: i18n.t(`payments.${Item.status}`),
                fees: Item.fees || 0,
            })
        })
    }
    else {
        users.map((Item, index) => {
            Refactored.push({
                key: index,
                amount: Item.amount,
                createdDate: moment(Item.createdDate).format('DD-MM-YYYY'),
                paymentMethod: i18n.t(`payments.${Item.paymentMethod}`),
                status: i18n.t(`payments.${Item.status}`),
                fees: Item.fees || 0,
            })
        })
    }
    return Refactored;
}

const getPaymentsCols = () => {
    let columns = [{
        title: i18n.t('payments.amount'),
        dataIndex: 'amount',
        key: 'amount',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.amount.length - b.amount.length,
    },
    {
        title: i18n.t('payments.fees'),
        dataIndex: 'fees',
        key: 'fees',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.fees.length - b.fees.length,
    },
    {
        title: i18n.t('users.StartDate'),
        dataIndex: 'createdDate',
        key: 'createdDate',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.createdDate.length - b.createdDate.length,

    },
    {
        title: i18n.t('payments.paymentMethod'),
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        sorter: (a, b) => a.paymentMethod.length - b.paymentMethod.length,
    },
    {
        title: i18n.t('users.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.length - b.status.length,
    },
    ]
    return columns;
}

const getStatusPayments = () => {
    return [
        {
            _id: 0,
            name: i18n.t("payments.SUCCESSED"),
            key: "SUCCESSED"
        },
        {
            _id: 1,
            name: i18n.t("payments.FAILED"),
            key: "FAILED"
        },
    ];
}


const getMethodsPayments = () => {
    return [
        {
            _id: 0,
            name: i18n.t("payments.INTENRNAL"),
            key: "INTENRNAL"
        },
        {
            _id: 1,
            name: i18n.t("payments.OPAY"),
            key: "OPAY"
        },
        {
            _id: 2,
            name: i18n.t("payments.MEZA_CARD"),
            key: "MEZA_CARD"
        },
        {
            _id: 3,
            name: i18n.t("payments.MEZA_DIGITAL"),
            key: "MEZA_DIGITAL"
        },
        {
            _id: 4,
            name: i18n.t("payments.CREDIT_CARD"),
            key: "CREDIT_CARD"
        }
    ];
}
export {
    getUsersCols,
    refactoreUsers,
    getaddUserValidationSchema,
    getGenders,
    getStatus,
    initPriv,
    getResetPasswordValidationSchema,
    refactorePrivillages,
    initRoles,
    getSelectedBranch,
    getSelectedBrand,
    getCompansateValidationSchema,
    getWalletsTypes,
    refactoreTransaction,
    getTransactionCols,
    refactorePayments,
    getPaymentsCols,
    getStatusPayments,
    getMethodsPayments
};
