
import CryptoJS from "crypto-js";
import Configs from "../config/config";

var globalsVariable = {
  languages: [],
  loggedIn: false,
  roles: [{ id: 1, name: "OWNER" }, { id: 2, name: "ADMIN" }],
  commonRegex: {
    number: /^[0-9]*$/,
    email: '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    phoneNumberWithPlus: /^\+?[0-9]+$/,
    onlyEnglishChars: /^[A-Za-z][A-Za-z0-9]*$/,
    phoneNumberStartWith05: '^((05))[0-9]{8}$',
    mobileNumberAtChallengeQuestions: '^(05)[0-9]{8}|(9665)[0-9]{8}|[0-9]{10}$',
    min2Chars: '^[a-zA-Z, |0-9]{2,}$',
    isRequired: '(^$)|(\s+$)'
  }
};


export const setLang = lang => {
  globalsVariable["currentLang"] = lang;
};

export const getlang = () => {
  return globalsVariable["currentLang"];
};

export const setVariable = (key, value) => {
  globalsVariable[key] = value;
};

export const getVariable = key => {
  return globalsVariable[key];
};


export const setLoggedIn = _loggedIn => {
  localStorage.setItem('loggedIn', _loggedIn.toString());
  globalsVariable["loggedIn"] = _loggedIn;
};

export const getLoggedIn = async () => {
  let res = await localStorage.getItem('loggedIn');
  return res
};


export const displayToast = (errMsg, success = false) => {
  var errDiv = document.getElementById("errorDiv");
  errDiv.style.display = "block";
  if (success) {
    errDiv.style.backgroundColor = "#4d9a60";
    errDiv.style.color = "#155724";
  }
  document.getElementById("errorTxt").innerText = errMsg;
  setTimeout(() => {
    errDiv.style.display = "none";
  }, 3000);
};


// const generateKey = (salt, passPhrase) => {
//   try {
//     var key = CryptoJS.PBKDF2(
//       passPhrase,
//       CryptoJS.enc.Hex.parse(salt),
//       { keySize: 4, iterations: 1000 });
//     return key;
//   }
//   catch (e) { }
// }

// const AESEncrypt = (plainText, key) => {
//   var encrypted = CryptoJS.AES.encrypt(plainText,
//     key,
//     { iv: CryptoJS.enc.Hex.parse(Configs.getIV) });
//   return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
// }

// export const encrypt = (plainText, encrypt = true) => {
//   try {
//     if (encrypt) {
//       let key = generateKey(Configs.getSalt, Configs.getPaassPharase);
//       if (typeof plainText == "string") {
//         return AESEncrypt(plainText, key);
//       }
//       else {
//         let result = [];
//         for (let i = 0; i < plainText.length; i++) {
//           result.push(AESEncrypt(plainText[i], key));
//         }
//         return result;
//       }
//     }
//     else {
//       return plainText;
//     }
//   }
//   catch (e) {
//   }
// }


const AESEncrypt = (plainText, _key, _iv) => {
  try {
      let encrypted = CryptoJS.AES.encrypt(plainText, _key, { iv: _iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding });
      return encrypted.toString();
  }
  catch (e) {
  }
}

export const encrypt = (plainText, encrypt = true) => {
  try {
      if (encrypt) {

          var _key = CryptoJS.enc.Latin1.parse(Configs.getKey);
          var _iv = CryptoJS.enc.Latin1.parse(Configs.getIV);

          if (typeof plainText == "string") {
              return AESEncrypt(plainText, _key, _iv);
          }
          else {
              let result = [];
              for (let i = 0; i < plainText.length; i++) {
                  result.push(AESEncrypt(plainText[i], _key, _iv));
              }
              return result;
          }
      }
      else {
          return plainText;
      }
  }
  catch (e) {
  }
}



