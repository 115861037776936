import config from '../../config';
import { SET_USERS, SET_USERS_REFACTORED, SET_SELECTED_USER } from '../constants/usersConstants';

const initialState = {
    users: null,
    refactoredUsers: null,
    selectedUser: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                users: action.payload
            };
        case SET_USERS_REFACTORED:
            return {
                ...state,
                refactoredUsers: action.payload
            };
        case SET_SELECTED_USER: {
            return {
                ...state,
                selectedUser: action.payload
            };
        }
        default:
            return state;
    }
};

export default reducer;