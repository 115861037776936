import { SET_NOTIFICATION, SET_NOTIFICATION_REFACTORED } from '../constants/notificationsConstants';

const initialState = {
    notification: null,
    refactoredNotification: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            };
        case SET_NOTIFICATION_REFACTORED:
            return {
                ...state,
                refactoredNotification: action.payload
            };
        default:
            return state;
    }
};

export default reducer;