import { SET_BRANDS, SET_BRANDS_REFACTORED, SET_SELECTED_BRAND, SET_BRANCHES, SET_BRANCHES_REFACTORED, SET_SELECTED_BRANCH, SET_ALL_BRANCHES, SET_ALL_BRANDS } from '../constants/brandsConstants';

const initialState = {
    brands: null,
    refactoredBrands: null,
    selectedBrand: null,
    branches: null,
    refactoredBranches: null,
    selectedBranch: null,
    allBrands: null,
    allBranches: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BRANDS:
            return {
                ...state,
                brands: action.payload
            };
        case SET_BRANDS_REFACTORED:
            return {
                ...state,
                refactoredBrands: action.payload
            };
        case SET_SELECTED_BRAND: {
            return {
                ...state,
                selectedBrand: action.payload
            };
        }
        case SET_BRANCHES:
            return {
                ...state,
                branches: action.payload
            };
        case SET_BRANCHES_REFACTORED:
            return {
                ...state,
                refactoredBranches: action.payload
            };
        case SET_SELECTED_BRANCH: {
            return {
                ...state,
                selectedBranch: action.payload
            };
        }
        case SET_ALL_BRANCHES: {
            return {
                ...state,
                allBranches: action.payload
            };
        }
        case SET_ALL_BRANDS: {
            return {
                ...state,
                allBrands: action.payload
            };
        }
        default:
            return state;
    }
};

export default reducer;