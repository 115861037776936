const SET_BRANDS = 'SET_BRANDS';
const SET_BRANDS_REFACTORED = 'SET_BRANDS_REFACTORED';
const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
const SET_BRANCHES = 'SET_BRANCHES';
const SET_BRANCHES_REFACTORED = 'SET_BRANCHES_REFACTORED';
const SET_SELECTED_BRANCH = 'SET_SELECTED_BRANCH';
const SET_ALL_BRANCHES = 'SET_ALL_BRANCHES';
const SET_ALL_BRANDS = 'SET_ALL_BRANDS';

export { SET_BRANDS, SET_BRANDS_REFACTORED, SET_SELECTED_BRAND, SET_BRANCHES, SET_BRANCHES_REFACTORED, SET_SELECTED_BRANCH, SET_ALL_BRANCHES, SET_ALL_BRANDS };
