import React, { Component } from 'react';
import windowSize from 'react-window-size';
import Aux from "../../hoc/_Aux";
import { Table, Popconfirm, Pagination } from 'antd';
import i18n from '../../i18n';
import { Row, Col, Dropdown } from 'react-bootstrap';
import './Table.scss';
import ReactExport from "react-export-excel";
import swal from 'sweetalert';
import sender from '../../assets/images/senderImg.png';
import recevier from '../../assets/images/recevier.png';

const ref = React.createRef();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Tables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            columns: props.cols
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { editAction, onRowSelect, showEdit = true, showDelete = false, hideActionCol = false, showResend = false, resendAction, giftSender = null, giftReciver = null, actionColName = null } = nextProps;
        let columns = [];
        if (nextProps.cols != prevState.cols) {
            columns = nextProps.cols;
            if (!hideActionCol) {
                columns.push({
                    title: actionColName ? actionColName : i18n.t("global.actions"),
                    dataIndex: 'operation',
                    render: (row, record) =>
                        <>
                            <div className="IconContainer">
                                {showEdit && <i className="fas fa-edit" onClick={() => { editAction(record) }} data-toggle="tooltip" data-placement="top" title="Edit" />}
                                {onRowSelect && <i className="fas fa-eye" onClick={() => { onRowSelect(record) }} data-toggle="tooltip" data-placement="top" title="Details" />}
                            {showDelete && <i className="fa fa-trash-o" onClick={() => { this.handleDelete(record) }} data-toggle="tooltip" data-placement="top" title="Delete" />}
                                {showResend && <i className="fa fa-redo" onClick={() => { resendAction(record) }} data-toggle="tooltip" data-placement="top" title="Resend" />}
                                {giftSender && <img src={require('../../assets/images/senderImg.png')} alt="loading" className="senderIcon" onClick={() => { giftSender(record) }} />}
                                {giftReciver && <img src={require('../../assets/images/recevier.png')} alt="loading" className="senderIcon" onClick={() => { giftReciver(record) }} />}
                            </div>
                        </>
                })
            }
            return {
                columns: columns
            }
        }
    }

    componentDidMount() {
        const { columns } = this.state;
        const { editAction, onRowSelect, showEdit = true, showDelete = false, hideActionCol = false, showResend = false, resendAction, giftSender = null, giftReciver = null, actionColName = null } = this.props;
        if (!hideActionCol) {
            columns.push({
                title: actionColName ? actionColName : i18n.t("global.actions"),
                dataIndex: 'operation',
                render: (row, record) =>
                    <>
                        <div className="IconContainer">
                            {showEdit && <i className="fas fa-edit" onClick={() => { editAction(record) }} data-toggle="tooltip" data-placement="top" title="Edit" />}
                            {onRowSelect && <i className="fas fa-eye" onClick={() => { onRowSelect(record) }} data-toggle="tooltip" data-placement="top" title="Details" />}
                            {showDelete && <i className="fa fa-trash-o" onClick={() => { this.handleDelete(record) }} data-toggle="tooltip" data-placement="top" title="Delete" />}
                            {showResend && <i className="fa fa-redo" onClick={() => { resendAction(record) }} data-toggle="tooltip" data-placement="top" title="Resend" />}
                            {giftSender && <img src={require('../../assets/images/senderImg.png')} alt="loading" className="senderIcon" onClick={() => { giftSender(record) }} />}
                            {giftReciver && <img src={require('../../assets/images/recevier.png')} alt="loading" className="senderIcon" onClick={() => { giftReciver(record) }} />}
                        </div>
                    </>
            })
            this.setState({ columns })
        }

    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    handleDelete = (record) => {
        const { deleteAction } = this.props;

        swal({
            title: i18n.t("global.youSure"),
            text: i18n.t("global.deleteMsg"),
            icon: "error",
            buttons: [i18n.t("global.cancel"), i18n.t("global.confirm")],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    deleteAction(record);
                }
            });
    }

    checkPages = () => {
        const { TotalElemnt, onPageChange, currentPage = null } = this.props;
        let result = {
            showSizeChanger: false, defaultCurrent: currentPage ? currentPage + 1 : 1, position: ['bottomCenter'], defaultPageSize: 10, total: TotalElemnt, onChange: (page, size) => { onPageChange(page, size) }
        }
        if (currentPage >= 0) {
            result.current = currentPage + 1
        }
        return result;
    }


    render() {
        const { selectedRowKeys, columns } = this.state;
        const { showAdd = true, addAction, Title, filters, data, searchAction = null, TotalElemnt, onPageChange, onRowSelect, resetAction, currentPage = null, optionsItemsClicked } = this.props;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        let excelCols = [];
        columns.map((Item) => {
            if (Item.dataIndex != 'operation') {
                excelCols.push(Item)
            }
        })

        return (
            <div className="TableComponentContainer">

                <Row className="headerContainer">
                    <Col md="4">
                        <h2>{Title}</h2>
                    </Col>
                    <Col md="8" className="btnContainer">
                        {showAdd && <button className="btn btn-primary shadow-2 mb-4 buttonTheme" onClick={addAction}>{i18n.t("global.Add")}</button>}
                        {searchAction && <button className="btn btn-primary shadow-2 mb-4 buttonTheme" onClick={searchAction}>{i18n.t("global.search")}</button>}
                        {searchAction && <button className="btn btn-primary shadow-2 mb-4 buttonTheme" onClick={resetAction}>{i18n.t("global.reset")}</button>}
                        {
                            columns &&
                            <Dropdown>
                                <Dropdown.Toggle className="ExportDropDown"><i className="fa fa-download" /><p className="ExportTxt">{i18n.t("global.exportTable")}</p></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as='li' className="dropdown-item" onClick={() => {
                                        if (optionsItemsClicked) {
                                            optionsItemsClicked(0);
                                        }
                                    }}>
                                        <div className="csvText csvContainer"><i className="fa fa-file-excel" /> {i18n.t("global.exportTableAll")}</div>
                                    </Dropdown.Item>
                                    <Dropdown.Item as='li' className="dropdown-item" >
                                        <ExcelFile element={<div className="csvText csvContainer"><i className="fa fa-file-excel" /> {i18n.t("global.Excel")}</div>} filename={`${Title}`}>
                                            <ExcelSheet data={data} name={Title} >
                                                {
                                                    excelCols.map((Item, index) => {
                                                        return <ExcelColumn label={Item.dataIndex} value={Item.dataIndex} />

                                                    })
                                                }
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </Col>
                </Row>
                {filters ? filters : null}

                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            // onDoubleClick: event => onRowSelect(record, rowIndex), // click row
                        };
                    }}
                    pagination={this.checkPages()}
                    columns={columns} dataSource={data} bordered />
            </div>
        );
    }
}

export default windowSize(Tables);
