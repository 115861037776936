const ENDPOINT = "https://api.tahado.app/";
// const ENDPOINT = "https://staging-api.tahado.app/";
const API_SECRET = "tahado-client:secretvalue";
const SALT = "a2ca229cafbf73d8296e9048541c75a5";
const PASSPHRASE = "0EElojCKeD9KkVG";
const IV = "jghs72ljnds12PQ7";
const KEY = "Qx71b23IMn201BX8";
const TIMEOUT = 20000;

export default class ConfigClass {
  static get getEndpoint() {
    return ENDPOINT;
  }

  static get getApiSevret() {
    return API_SECRET;
  }

  static get getSalt() {
    return SALT;
  }

  static get getPaassPharase() {
    return PASSPHRASE;
  }

  static get getIV() {
    return IV;
  }

  static get getTimeout() {
    return TIMEOUT;
  }

  static get getKey() {
    return KEY;
  }
}
