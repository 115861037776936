import React from 'react';

const Login = React.lazy(() => import('./screens/Authentication/Login/Login'));
const DeleteAccount = React.lazy(() => import('./screens/Authentication/DeleteAccount'));
const PrivacyPreview = React.lazy(() => import('./screens/Authentication/PrivacyPreview'));

const route = [
    { path: '/Login', exact: true, name: 'Login', component: Login },
    { path: '/DeleteAccount', exact: true, name: 'DeleteAccount', component: DeleteAccount },
    { path: '/PrivacyPreview', exact: true, name: 'PrivacyPreview', component: PrivacyPreview },
    { path: '/', exact: true, name: 'Login', component: Login }
];

export default route;