import { SET_CATEGORIES, SET_CATEGORIES_REFACTORED, SET_SELECTED_CATEGORY, SET_CATEGORIES_ALL } from '../constants/categoryConstants';

const initialState = {
    categories: null,
    refactoredCategories: null,
    selectedCategories: null,
    allCategories: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case SET_CATEGORIES_REFACTORED:
            return {
                ...state,
                refactoredCategories: action.payload
            };
        case SET_SELECTED_CATEGORY: {
            return {
                ...state,
                selectedCategories: action.payload
            };
        } case SET_CATEGORIES_ALL: {
            return {
                ...state,
                allCategories: action.payload
            };
        }
        default:
            return state;
    }
};

export default reducer;