
import i18n from './i18n';
import { configureStore as store } from './store/configureStore';
import { refactorePrivillages } from './services/users/users.factory';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const checkRoles = (roles = null, type) => {
    let selectedRoles = [];
    if (roles?.length > 0) {
        roles.map((Item) => {
            selectedRoles.push(Item.name)
        })
    }
    return selectedRoles.includes(type);
}


const getStatus = () => {
    return [
        {
            _id: 0,
            name: i18n.t("users.ACTIVE")
        },
        {
            _id: 1,
            name: i18n.t("users.INACTIVE")
        },
    ];
}

const getFeatured = () => {
    return [
        {
            _id: 0,
            name: i18n.t("brands.featured")
        },
        {
            _id: 1,
            name: i18n.t("brands.notFeatured")
        },
    ];
}


const refactoreCategoriesCheckbox = (key) => {
    let selectedCategories = store.getState().storage.brandsState.brands?.content[key]?.categories || [];
    let Categories = store.getState().storage.categoryState.allCategories || [];

    let selectedCategory = [];
    let result = [];

    if (selectedCategories && selectedCategories.length > 0) {
        selectedCategories.map((Item) => {
            selectedCategory.push(Item.name)
        })
    }

    Categories.map((Item) => {
        result.push({
            selected: selectedCategories.length > 0 ? selectedCategory.includes(Item.name) : false,
            value: Item
        })
    })
    return result;
}


const getDropDownItems = (data = null, checkLang = false) => {
    let result = [];
    if (checkLang) {
        if (data) {
            data.map((Item) => {
                result.push({
                    _id: Item.id,
                    name: getSelectedLang() == "en" ? Item.name : Item.nameAr,
                    nameAr: Item.nameAr
                })
            })
        }
    }
    else {
        if (data) {
            data.map((Item) => {
                result.push({
                    _id: Item.id,
                    name: Item.name
                })
            })
        }
    }

    return result
}

const navigateToFirstScreen = (privileges, history) => {
    if (privileges.includes("DASHBOARD_VIEW")) {
        history.push('/dashboardUsers')
    }
    else if (privileges.includes('USERS_VIEW')) {
        history.push('/users');
    }
    else if (privileges.includes('ADMINS_VIEW')) {
        history.push('/admins');
    }
    else if (privileges.includes('DOC_VIEW')) {
        history.push('/policyManagment');
    }
    else if (privileges.includes('LOCATION_VIEW')) {
        history.push('/Countries');
    }
    else if (privileges.includes('BRANDS_VIEW')) {
        history.push('/Brands');
    }
    else if (privileges.includes('CATEGORY_VIEW')) {
        history.push('/Categories');
    }
    else if (privileges.includes('FEEDBACK_VIEW')) {
        history.push('/Feedback');
    }
    else if (privileges.includes("GIFTS_VIEW")) {
        history.push('/Gifts')
    }
    else if (privileges.includes("SEND_PUSH_NOTIFICATION")) {
        history.push('/Notification')
    }
    else if (privileges.includes("PAYMENTS_VIEW")) {
        history.push('/Payments')
    }
}

const getSelectedLang = () => {
    return localStorage.getItem("lang");
}

const exportToExcel = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
}

export { checkRoles, getStatus, getFeatured, refactoreCategoriesCheckbox, getDropDownItems, navigateToFirstScreen, getSelectedLang, exportToExcel }