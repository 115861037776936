import config from '../../config';
import { SET_ADMINS, SET_ADMINS_REFACTORED, SET_SELECTED_ADMIN } from '../constants/adminsConstants';

const initialState = {
    admins: null,
    refactoredAdmins: null,
    selectedAdmin: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADMINS:
            return {
                ...state,
                admins: action.payload
            };
        case SET_ADMINS_REFACTORED:
            return {
                ...state,
                refactoredAdmins: action.payload
            };
        case SET_SELECTED_ADMIN: {
            return {
                ...state,
                selectedAdmin: action.payload
            };
        }
        default:
            return state;
    }
};

export default reducer;