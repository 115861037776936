import { PropTypes, func } from "prop-types";
import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './DropDownStyle.scss';
import i18n from '../../i18n';
import { getSelectedLang } from '../../helpers';

const handleScroll = (e, onReachEnd) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
        onReachEnd && onReachEnd()
    }
}

const getTitle = (checkLang, selctedItem, label) => {
    if (checkLang) {
        if (selctedItem) {
            return getSelectedLang() == "en" ? selctedItem.name : selctedItem.nameAr
        }
        else {
            return label;
        }
    } else {
        if (selctedItem) {
            return selctedItem.name
        }
        else {
            return label;
        }
    }

}

const DropDown = ({ label, items, selctedItem, onClick, title, errorMsg = null, touched, OnBlur = null, onReachEnd = null, checkLang = false }) => {
    return (
        <div className="CustomeDropDown">
            {title && <label className="dropDownTitle">{title}</label>}
            <UncontrolledDropdown className={[errorMsg && touched ? "UncontrolledDropdownContainer errorDropDown" : "UncontrolledDropdownContainer"]} style={title ? { marginTop: 0 } : null} >
                <DropdownToggle caret nav >
                    {getTitle(checkLang, selctedItem, label)}
                    <i className="fas fa-caret-down"></i>
                </DropdownToggle>
                <DropdownMenu onBlur={() => OnBlur ? OnBlur() : null} onScroll={(e) => handleScroll(e, onReachEnd)}  >
                    {
                        items && items.length > 0 ? items.map((Item, key) => {
                            return <DropdownItem key={key} onClick={() => { onClick(Item) }} active={selctedItem && Item._id == selctedItem._id}>{Item.name}</DropdownItem>
                        }) :
                            <label className="noDataLabel">{i18n.t("global.noData")}</label>
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
            {errorMsg && touched && <label style={{ color: '#ea6464', marginLeft: '10px', fontSize: '12px' }}>{errorMsg}</label>}
        </div >
    );
};

DropDown.defaultProps = {
    label: "",
    items: null,
    selctedItem: null,
    onClick: null,
    title: null
};

DropDown.propTypes = {
    label: PropTypes.string,
    items: PropTypes.array,
    selctedItem: PropTypes.object,
    onClick: func,
    title: PropTypes.string


};

export { DropDown };
