import config from '../../config';
import { SET_COUNTRIES, SET_COUNTRIES_REFACTORED, SET_CITIES, SET_REGIONS, SET_CITIES_REFACTORED, SET_REGIONS_REFACTORED } from '../constants/countriesConstants';

const initialState = {
    countries: null,
    refactoredCountries: null,
    cities: null,
    regions: null,
    refactoredCities: null,
    refactoredRegions: null

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            };
        case SET_COUNTRIES_REFACTORED:
            return {
                ...state,
                refactoredCountries: action.payload
            };
        case SET_CITIES:
            return {
                ...state,
                cities: action.payload
            };
        case SET_REGIONS:
            return {
                ...state,
                regions: action.payload
            };
        case SET_CITIES_REFACTORED:
            return {
                ...state,
                refactoredCities: action.payload
            };
        case SET_REGIONS_REFACTORED:
            return {
                ...state,
                refactoredRegions: action.payload
            };
        default:
            return state;
    }
};

export default reducer;