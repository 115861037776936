import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import GlobalReducer from './reducers/GlobalReducer';
import authReducer from './reducers/authReducer';
import usersReducer from './reducers/usersReducer';
import adminsReducer from './reducers/adminsReducer';
import countriesReducer from './reducers/countriesReducer';
import brandsReducer from './reducers/brandsReducer';
import categoryReducer from './reducers/categoryReducer';
import notificationsReducer from './reducers/notificationsReducer';
import giftsReducer from './reducers/giftsReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { CLEAR_REDUCERS } from './constants/ClearState';

const storageReducer = combineReducers({
    GlobalState: GlobalReducer,
    authState: authReducer,
    usersState: usersReducer,
    adminsState: adminsReducer,
    countriesState: countriesReducer,
    brandsState: brandsReducer,
    categoryState: categoryReducer,
    notificationsState: notificationsReducer,
    giftsState: giftsReducer
});

// Middleware: Redux Persist Config
const rootPersistConfig = {
    key: 'root',
    storage,
    // Whitelist (Save Specific Reducers)
    // whitelist: [],
};

// Middleware: Redux Persist Config
const storagePersistConfig = {
    key: 'storage',
    storage,
    whitelist: ['GlobalState', 'authState'],
};

const rootStorageReducer = (state, action) => {
    let updatedState = state;
    if (action.type === CLEAR_REDUCERS) {
        updatedState = undefined;
    }
    return storageReducer(updatedState, action);
};


const rootReducer = combineReducers({
    storage: persistReducer(storagePersistConfig, rootStorageReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
const configureStore = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(configureStore);

export { configureStore, persistor };



