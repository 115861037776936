import { PropTypes } from "prop-types";
import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import './Input.scss';

class InputWithText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsg: null,
            showPassword: false,
            type: 'text'
        }
    }

    componentDidMount() {
        const { inputType } = this.props;
        this.setState({ type: inputType })
    }



    showPassword() {
        const { showPassword } = this.state;
        this.setState({ showPassword: !showPassword }, () => {
            if (showPassword) {
                this.setState({ type: "password" })
            }
            else {
                this.setState({ type: "text" })
            }
        })
    }

    render() {
        const { label, value = null, placeholder, onChange, disabled, errorMsg, inputType, touched, onBlur } = this.props;
        const { type, showPassword } = this.state;
        return (
            <FormGroup className="InputWithTextContainer">
                {label && <label className="label">{label}</label>}
                <div className="InputContainer">
                    <Input className={errorMsg && touched && 'ErrorBorder'} type={type} placeholder={placeholder} onChange={onChange ? (val) => { onChange(val.target.value); } : null} disabled={disabled}
                        defaultValue={value ? value : null} onBlur={onBlur ? (val) => onBlur(val.target.value) : null} value={value} />
                    {inputType == 'password' && <i class={` eyeIcon ${showPassword ? "fas fa-eye" : "fas fa-eye-slash"}`} onClick={() => { this.showPassword() }}></i>}
                </div>
                <label className="errorLabel">{errorMsg && touched ? errorMsg : ''}</label>
            </FormGroup>
        )
    }
}
export default InputWithText;


InputWithText.defaultProps = {
    label: null,
    type: "text",
    onChange: null,
    placeholder: "",
    disabled: false,
    value: ""
};

InputWithText.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string

};

export { InputWithText };
