import { SET_GIFTS, SET_REFACTORED_GIFTS } from '../constants/giftsConstants';

const initialState = {
    gifts: null,
    refactoredGifts: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GIFTS:
            return {
                ...state,
                gifts: action.payload
            };
        case SET_REFACTORED_GIFTS:
            return {
                ...state,
                refactoredGifts: action.payload
            };
        default:
            return state;
    }
};

export default reducer;